<template>
  <DashboardSidebar>
    <DashboardSidebarLink
      to="/me/orders"
      v-if="$configuration.pastOrdersEnabled"
      data-testid="orderHistoryLink"
    >
      {{ $t('orderHistory') }}
    </DashboardSidebarLink>

    <DashboardSidebarLink
      to="/me/items"
      v-if="$configuration.pastItemsEnabled"
      data-testid="buyItAgainLink"
    >
      {{ $t('buyItAgain') }}
    </DashboardSidebarLink>

    <DashboardSidebarLink
      to="/me/lists"
      v-if="$configuration.listEnabled"
      data-testid="shoppingListsLink"
    >
      {{ $t('shoppingLists') }}
    </DashboardSidebarLink>

    <DashboardSidebarLink
      to="/me/rewards"
      v-if="$configuration.rewardsEnabled"
      data-testid="rewardsLink"
    >
      {{ $t('rewards') }}
    </DashboardSidebarLink>

    <DashboardSidebarLink
      to="/me/wallet"
      v-if="$configuration.walletEnabled"
      data-testid="walletLink"
    >
      {{ $t('wallet') }}
    </DashboardSidebarLink>

    <DashboardSidebarLink to="/me/edit" data-testid="accountSettingsLink">
      {{ $t('accountSettings') }}
    </DashboardSidebarLink>

    <DashboardSidebarLink
      to="/me/coop"
      v-if="$configuration.cmsEnabled"
      data-testid="membershipDetailsLink"
    >
      {{ $t('membershipDetails') }}
    </DashboardSidebarLink>

    <DashboardSidebarLink to="/me/password" data-testid="changePasswordLink">
      {{ $t('changePassword') }}
    </DashboardSidebarLink>

    <DashboardSidebarLink
      to="/me/programs"
      v-if="customerPrograms"
      data-testid="customerProgramsLink"
    >
      {{ $t('customerPrograms') }}
    </DashboardSidebarLink>

    <li class="unstyled-list-item">
      <DashboardSidebarButton @click="logout" data-testid="signOutButton">
        {{ $t('signOut') }}
      </DashboardSidebarButton>
    </li>
  </DashboardSidebar>
</template>

<script>
import UserMixin from '@/modules/user/mixins/UserMixin';
import DashboardSidebarLink from '@/layouts/components/Dashboard/DashboardSidebarLink.vue';
import DashboardSidebarButton from '@/layouts/components/Dashboard/DashboardSidebarButton.vue';
import DashboardSidebar from '@/layouts/components/Dashboard/DashboardSidebar.vue';

export default {
  name: 'WCAccountSidebar',
  components: { DashboardSidebar, DashboardSidebarLink, DashboardSidebarButton },
  mixins: [UserMixin],
  computed: {
    customerPrograms() {
      return (
        this.$configuration.customerGroups?.length > 0 &&
        this.$configuration.customerGroups.filter(el => el.groups?.length > 0).length > 0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.unstyled-list-item {
  list-style-type: none;
}
</style>
